/* eslint-env browser */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReactGA from 'react-ga'

const GA_ID = 'UA-119165336-2'

const isProduction = process.env.NODE_ENV !== 'development'

if (isProduction) {
  ReactGA.initialize(GA_ID)
  ReactGA.pageview(window.location.pathname + window.location.search)
}
