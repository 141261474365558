// import React from 'react'
import styled from '@emotion/styled'

import {
  space,
  color,
  width,
  height,
  fontSize,
  flex,
  alignSelf,
  order,
  lineHeight,
  position,
  zIndex,
  display,
} from 'styled-system'

const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  color,
  width,
  height,
  fontSize,
  flex,
  alignSelf,
  order,
  lineHeight,
  position,
  zIndex,
  display
)

Box.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...flex.propTypes,
  ...alignSelf.propTypes,
  ...order.propTypes,
  ...lineHeight.propTypes,
  ...position.propTypes,
  ...zIndex.propTypes,
  ...display.propTypes,
}

export default Box
