import { fontFace } from 'polished'

import theme from './theme'

export default {
  body: {
    // background: theme.colors.lightGray,
    // color: theme.colors.mediumGray,

    // https://developers.google.com/web/updates/2017/11/overscroll-behavior
    overscrollBehaviorY: 'none',
    minHeight: '100%',
  },

  // Bold
  ...[
    ...fontFace({
      fontFamily: 'GothamRoundedBold',
      fontFilePath: '/fonts/GothamRnd-Bold',
      fileFormats: ['otf'],
      fontWeight: 'normal',
      fontStyle: 'normal',
    }),

    // Book
    ...fontFace({
      fontFamily: 'GothamRoundedBook',
      fontFilePath: '/fonts/GothamRnd-Book',
      fileFormats: ['otf'],
      fontWeight: 'normal',
      fontStyle: 'normal',
    }),

    // Light
    ...fontFace({
      fontFamily: 'GothamRoundedLight',
      fontFilePath: '/fonts/GothamRnd-Light',
      fileFormats: ['otf'],
      fontWeight: 'normal',
      fontStyle: 'normal',
    }),

    // Euclid Flex Ultralight
    ...fontFace({
      fontFamily: 'GothamRoundedMedium',
      fontFilePath: '/fonts/GothamRnd-Medium',
      fileFormats: ['otf'],
      fontWeight: 'normal',
      fontStyle: 'normal',
    }),
  ],

  'body, html, p, span, a': {
    fontFamily: 'GothamRoundedLight',
  },

  'h1, h2, h3, h4, h5, h6,': {
    fontFamily: 'GothamRoundedMedium',
  },

  a: {
    color: theme.colors.purple,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    outline: 'none',
  },

  '.cross-fade-leave': {
    opacity: 1,
  },

  '.cross-fade-leave.cross-fade-leave-active': {
    opacity: 0,
    transition: 'opacity 200ms ease-in 200ms',
  },

  '.cross-fade-enter': {
    opacity: 0,
  },

  '.cross-fade-enter.cross-fade-enter-active': {
    opacity: 1,
    transition: 'opacity 200ms ease-in',
  },

  '.cross-fade-height': {
    transition: 'height 200ms ease-in-out',
  },
}
