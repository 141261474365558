import React from 'react'

const SVG = React.forwardRef((props, ref) => (
  <svg x="0px" y="0px" viewBox="0 0 934 706" {...props} ref={ref}>
    <title>makrlogo</title>
    <path
      style={{
        fill: '#FFFFFF',
        stroke: '#231F20',
        strokeWidth: 9,
        strokeMiterlimit: 10,
      }}
      d="M718.4,547.3c-14.1-93.5-39.4-128.7-86.7-158.4
	c0,0,34.9-56.7,14.8-148.8c0,0-1.3-8.3-13-6.9s-37.3,3.4-36.6,12.4c0.6,8.2,11.8,82-6.2,112.3c-0.4,0.2-4,7.1-8.6,8
	c-9.5,2-9.7-3.2-10.9-6.7c-2.4-7.4-21.1-79.3-21.6-81.2c-3.1-14.7-16.8-12.6-31.6-8.5c-26.5,7.3-30.5,7.4-24.4,31.8
	c7,27.7,61.5,258.9,67.6,277.5s19.5,16.8,33,14.2s30.5-7.5,29.3-21.3c-0.8-9.6-19.6-84.5-23.9-100.5c-2.1-7.8-2.1-18.4,6.8-20.6
	c12-3,14.8,3.5,19.5,10.5c14.4,21.3,31.9,109,45.9,113.6c9.7,3.2,43.1-1.4,46.6-9.9c1-2.5,0.3-12.4,0.1-15.5
	C718.5,548.6,718.4,548,718.4,547.3"
    />
    <path
      style={{
        fill: '#FFFFFF',
        stroke: '#231F20',
        strokeWidth: 9,
        strokeMiterlimit: 10,
      }}
      d="M208.8,341.5c-9.2-26.3-21.2-24-42.5-20.1
	c-15.8,2.9-22.5,9.3-24.1,11.1c0,0-0.1,0-0.1,0.1c-2.9,2.3-4.9,8-5.2,12.9c-1.2,16.7,6.8,53.1,7.3,80.5c0.2,10.2-7.6,12.5-11.6,13.3
	c-4.8,0.9-12.7,1.1-16.6-7.9c-10.2-23.4-16.3-69.3-23.8-80.3c-8.2-12-18.5-14.1-41.2-8.5S22.1,358.8,21,366.5
	c-3.5,23.3,11.9,233.6,27.7,306.7c2.1,9.8,5,17.2,15.6,17.5c12.4,0.3,20.7-0.8,28.7-3.8c10.2-3.8,11.2-10,10.6-17.9l-10.2-73.4
	c0,0-3.6-24.1-6-40.2s6.3-19,13.2-20.2c10.7-1.8,18,3.9,20.6,15.2l13.8,60.1c2.4,15.8,21.5,14.6,31.1,12.9
	c10.6-1.9,17.5-5.4,17.7-16.3l-8.7-64.7c0.2-8.1,1.3-17.3,14-19.7c11-2,17.6,4,20.3,14.9c3.2,12.8,10.5,52.1,10.5,52.1l10.1,53.8
	c3.1,16.1,14.5,15.5,20,14.7c25.5-3.8,30.4-6.5,27.2-29.9C271.7,588.8,224.2,385.5,208.8,341.5"
    />
    <path
      style={{
        fill: '#1F2122',
        stroke: '#231F20',
        strokeWidth: 9,
        strokeMiterlimit: 10,
      }}
      d="M487.7,418.1c-4.1-35.5-82-262.7-95-289.3
	s-23.3-32.6-68.8-19.3c-28.9,8.4-41.2,14.8-46.1,24.3l0,0c-1.3,2.5-24.8,80.3-25.2,82.4v0.1l0,0c-0.5,3.1-0.7,6.2-0.7,9.2
	c0.1,20,29.8,221.6,40,276.9c6.4,34.5,12.5,36.6,35,34.6c17.4-1.6,36.6-7.5,37.7-20l16.2-53.6c2.1,0.8,3.9,2.5,5.6,5.8
	c6.2,12.2,9,31.9,13.9,41.5c5.7,11.2,19.8,11.2,32.7,7.8c10.8-2.8,26.9-4.9,31-16.2l22.8-75.1C487.8,424.2,488.1,421.1,487.7,418.1"
    />
    <path
      style={{
        fill: '#FFFFFF',
        stroke: '#231F20',
        strokeWidth: 9,
        strokeMiterlimit: 10,
      }}
      d="M393,128.7c-13-26.6-23.4-32.6-68.9-19.4
	C281.4,121.8,274.9,130,275,150s29.8,221.6,40,276.9c6.4,34.5,12.3,35.7,34.8,33.6c17.4-1.6,36.8-6.6,37.8-19.1
	c0.9-10.6-3.2-26.5-3.5-39c-0.2-9.2,2.4-12,9.9-14s11.7-2.1,15.5,5.2c6.2,12.2,9,31.9,13.9,41.5c5.7,11.2,19.9,12.1,32.8,8.8
	c13.1-3.4,33.9-7.1,31.8-25.8C484,382.5,406,155.3,393,128.7z M396.3,335.3c-4.7,1-16.9,2.8-22.6,4c-11,2.3-12.9-12.3-12.9-12.3
	s-21.7-122.1-23-132.3c-1-7.5,6.3-10.8,11.6-12.2c11.6-3.1,15.5,2,16.9,5.2c5,11.6,34.1,109.1,38.2,121.1
	C410.9,327.7,406.5,333.1,396.3,335.3L396.3,335.3z"
    />
    <path
      style={{
        fill: '#1F2122',
        stroke: '#231F20',
        strokeWidth: 9,
        strokeMiterlimit: 10,
      }}
      d="M864.7,239.2c7.8-18.2,8.4-60.1,6.8-83.2
	c-2-28.8-12.1-57.1-19.1-70.2C838,58.8,802.3,8.6,743.9,18c-29,4.7-50.3,12.4-54.4,29.9l-22.8,75.5c-4.3,18.4,56.9,300.9,59.3,313
	c3.3,16.4,10,24.2,44.7,18.5c15.2-2.5,23.4-5.4,25.4-12.1l21.4-72.7c0.3-0.5,0.6-1,0.9-1.5c4.5,17.2,10.4,40,13.9,56.2
	c3.4,16,15.6,15.6,35.1,12.1c7.6-1.4,14.9-4.1,21.6-8l23.4-76.2l-0.8-0.2c1.7-2.7,2.2-6.9,2-13.8
	C913.4,335.1,912.6,259.7,864.7,239.2"
    />
    <path
      style={{
        fill: '#FFFFFF',
        stroke: '#231F20',
        strokeWidth: 9,
        strokeMiterlimit: 10,
      }}
      d="M820.1,200.8c-2.9,19.9-7.8,21.9-21.4,24.8
	c-5.6,1.1-15.4,1.5-18.7-13.6c0,0-27.4-121.5-27.1-129.9s31.7-11.7,49.3,23.1c6.7,13.2,11.9,24,15.1,45.2
	C821.3,176.7,822.4,185.4,820.1,200.8 M864.5,237.5c9.3-24.3,8.4-60.1,6.8-83.2c-2-28.8-12.1-57.1-19.1-70.2
	c-14.4-27-50.1-77.2-108.5-67.8c-29,4.7-50.3,12.4-54.5,29.9c-4.3,18.4,56.9,300.9,59.3,313c3.3,16.4,10,24.2,44.7,18.5
	c21.5-3.6,29.2-7.9,24.6-22.8c0,0-8.8-40.3-12.3-58.8c-3.2-16.7,6.2-17,14.1-18.7c11.5-2.5,18.6,3.8,21,13.2
	c4.5,17.3,10.6,40.5,14.1,57c3.4,16,15.2,13.7,34.7,10.2s24.2-1.9,23.8-20.8C913.3,337.1,913.7,258.9,864.5,237.5L864.5,237.5z"
    />
  </svg>
))

export default SVG
