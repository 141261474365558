import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Box from './box'
// import Chiclet from './svg/chiclet'
import WordMark from './svg/wordmark'

import theme from './theme'

const { sizes } = theme

const Header = () => (
  <Box height={sizes.nav} px={4} backgroundColor="purple">
    <Box height={sizes.nav} py={3}>
      <Link to="/">
        <WordMark
          css={{
            height: '100%',
            width: 'auto',
          }}
        />
      </Link>
    </Box>
  </Box>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
